import React from 'react'
import {toast} from 'react-toastify'
import {useAuthClient} from '../../utils/use-auth-client'
import {AlphaSEO} from './seo-view-v4'

function SEOSummary({
  alphaProposalId,
  setAlphaProposalId,
  alphaDomain,
  setAlphaDomain,
  alphaAmount,
  setAlphaAmount,
  alphaKeyCount,
  setAlphaKeyCount,
  alphaPowerboostCount,
  setAlphaPowerboostCount,
  diableValidateAndProposalInput,
  isExternalDomain,
  setIsExternalDomain,
}) {
  const client = useAuthClient()

  const propsalChange = function (e) {
    console.log('From propsalChange :: ', e.target.value)
    setAlphaProposalId(e.target.value)
  }

  const incrementKeyword = function () {
    console.log('Inside incrementKeyword')
    setAlphaKeyCount(alphaKeyCount + 1)
  }

  const incrementPowerboost = function () {
    console.log('Inside incrementPowerboost')
    setAlphaPowerboostCount(alphaPowerboostCount + 1)
  }

  const decrementKeyword = function () {
    console.log('Inside decrementKeyword')
    setAlphaKeyCount(alphaKeyCount - 1)
  }

  const decrementPowerboost = function () {
    console.log('Inside decrementPowerboost')
    setAlphaPowerboostCount(alphaPowerboostCount - 1)
  }

  const validateProposalId = async () => {
    console.log('OnClick of validate proposal :: ', alphaProposalId)
    try {
      const validationResult = await client(
        `orders/alphaseo/validate-proposal?proposalId=${alphaProposalId}`,
      )
      console.log('Validation Result is ::', validationResult)
      if (validationResult.isSuccess) {
        setAlphaAmount(validationResult.proposalInfo.price)
        setAlphaDomain(validationResult.proposalInfo.domain)
        console.log('domain :: ', alphaDomain)
        console.log('amount ::', alphaAmount)
        toast.success('Valid proposal ID provided.')
      } else {
        console.log('Error ::', validationResult.Error)
        setAlphaAmount(0)
        setAlphaDomain('')
        toast.error('Invalid proposalId')
      }
    } catch (ex) {
      console.log('Error :', ex)
    }
  }
  return (
    <AlphaSEO
      alphaProposalId={alphaProposalId}
      alphaDomain={alphaDomain}
      alphaAmount={alphaAmount}
      keywordCount={alphaKeyCount}
      powerboostCount={alphaPowerboostCount}
      handleChange={propsalChange}
      validateProposalId={validateProposalId}
      diableValidateAndProposalInput={diableValidateAndProposalInput}
      incrementKeyword={incrementKeyword}
      decrementKeyword={decrementKeyword}
      incrementPowerboost={incrementPowerboost}
      decrementPowerboost={decrementPowerboost}
      isExternalDomain={isExternalDomain}
      setIsExternalDomain={setIsExternalDomain}
    />
  )
}

export {SEOSummary}
