import React, {useState} from 'react'
import {ParagraphText, Icon} from '@thryvlabs/maverick'
import {CartItem} from './cart-item'
import {TotalDueContainer} from '../ui/payment-information'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {formatPrice} from '../../utils/currency-format'

const CCDesktopTodaySummary = ({
  todayItems,
  getProducts,
  itemComparator,
  isAusNZ,
  hasSupportFee,
  paymentState,
  supportFee,
  totalNoTaxes,
  couponDiscountAmount,
}) => {
  const [taxesAndFeesOpen, setTaxesAndFeesOpen] = useState(true)
  const {frontendPreviewPayment} = useFlags()

  const paymentPreview = paymentState.paymentInfo?.paymentPreview
  const isAus = paymentState.paymentInfo?.country === 'AU'
  const isSEOPreviewAvailable = paymentPreview?.apps?.some(({planCode}) =>
    ['seo-5-thryv-m2m', 'seombg-5-thryv-m2m'].includes(planCode),
  )
  const isInvoiceBilled = paymentState?.paymentInfo?.isInvoiceBilled

  function getTodayAmount() {
    let todayAmount
    if (!frontendPreviewPayment || !paymentState.paymentInfo?.paymentPreview) {
      todayAmount = paymentState?.totalPaymentAmount
        ? paymentState?.totalPaymentAmount
        : Math.max(totalNoTaxes - couponDiscountAmount, 0)
      // paymentState.calculatedTaxes > 0 && !isAusNZ
      //   ? paymentState?.totalPaymentAmount
      //   : Math.max(totalNoTaxes - couponDiscountAmount, 0)
    } else {
      todayAmount = paymentState.paymentInfo.paymentPreview.total
    }
    console.log({paymentState})
    return Math.max(
      todayAmount +
        (paymentState?.paymentInfo?.balanceInfo?.availableCreditAmount || 0),
      0,
    )
  }

  function getTaxAmount() {
    if (!frontendPreviewPayment || !paymentState.paymentInfo?.paymentPreview) {
      return paymentState.calculatedTaxes !== null
        ? paymentState.calculatedTaxes
        : 'To be calculated'
    }
    return paymentState.paymentInfo.paymentPreview.tax
  }

  function getFeeAmount() {
    if (!frontendPreviewPayment || !paymentPreview) {
      return parseFloat(supportFee.currency.unitAmount)
    }
    const {subtotal, tax} = paymentPreview.apps.find(
      ({planCode}) => planCode === '247support-m2m',
    )
    return isAus ? Math.max(subtotal + tax, 0) : Math.max(subtotal, 0)
  }

  return (
    <>
      <ParagraphText
        variant="lg"
        style={{fontSize: '16px', fontWeight: 'bold'}}
      >
        Due {isInvoiceBilled ? 'Next Invoice' : 'Today'}
      </ParagraphText>

      <div className="monthly_container">
        {todayItems &&
          getProducts(todayItems, true)
            .filter(item => item.code !== '247support-m2m')
            .sort(itemComparator)
            .map((item, index) => (
              <CartItem
                key={index}
                item={item}
                isToday
                paymentPreview={paymentState.paymentInfo?.paymentPreview}
              />
            ))}
        {paymentState?.paymentInfo?.balanceInfo?.availableCreditAmount && (
          <div className="d-flex justify-content-between">
            <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
              Credit
            </ParagraphText>
            <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
              {formatPrice(
                paymentState?.paymentInfo?.balanceInfo?.availableCreditAmount ||
                  0,
              )}
            </ParagraphText>
          </div>
        )}
        {!isAusNZ && (
          <div className="d-flex justify-content-between align-items-center">
            <ParagraphText
              variant="reg"
              className="m-0 font-weight-bold"
              style={{color: '#4D4D4D'}}
            >
              Tax & Fees
            </ParagraphText>

            <>
              <button
                className="w-11/12 mr-3"
                onClick={() => setTaxesAndFeesOpen(prevVal => !prevVal)}
                aria-describedby={`Click to access the charges due today below.`}
              >
                <div
                  className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
                >
                  <div className="flex flex-row-reverse text-center gap-4">
                    <Icon
                      type={'solid'}
                      variant={`caretDown`}
                      className={`fill-current text-thryv-black-500 -rotate-90 ${
                        taxesAndFeesOpen && 'rotate-0'
                      } transition-all duration-300 ease-in-out cursor-pointer`}
                      height={'10'}
                      width={'10'}
                    />
                    <ParagraphText
                      variant="reg"
                      className="m-0 font-weight-bold"
                      style={{color: '#4D4D4D'}}
                    >
                      &nbsp;
                    </ParagraphText>
                  </div>
                </div>
              </button>
            </>
          </div>
        )}
        {isAusNZ && hasSupportFee && (
          <div className="d-flex justify-content-between align-items-center">
            <ParagraphText
              variant="reg"
              className="m-0 font-weight-bold"
              style={{color: '#4D4D4D'}}
            >
              Fees
            </ParagraphText>

            <>
              <button
                className="w-11/12 mr-3"
                onClick={() => setTaxesAndFeesOpen(prevVal => !prevVal)}
                aria-describedby={`Click to access the charges due today below.`}
              >
                <div
                  className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
                >
                  <div className="flex flex-row-reverse text-center gap-4">
                    <Icon
                      type={'solid'}
                      variant={`caretDown`}
                      className={`fill-current text-thryv-black-500 -rotate-90 ${
                        taxesAndFeesOpen && 'rotate-0'
                      } transition-all duration-300 ease-in-out cursor-pointer`}
                      height={'10'}
                      width={'10'}
                    />
                    <ParagraphText
                      variant="reg"
                      className="m-0 font-weight-bold"
                      style={{color: '#4D4D4D'}}
                    >
                      &nbsp;
                    </ParagraphText>
                  </div>
                </div>
              </button>
            </>
          </div>
        )}

        <div
          className={`${
            !taxesAndFeesOpen
              ? 'hidden opacity-0 scale-0 transition-all duration-500 ease-in-out '
              : 'visible opacity-100 scale-100 transition-all duration-700 ease-in-out '
          }`}
        >
          {!isAusNZ && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                Tax
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                {formatPrice(getTaxAmount())}
              </ParagraphText>
            </div>
          )}
          {hasSupportFee ? (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                24/7 Service & Support Fee
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                {formatPrice(getFeeAmount())}
              </ParagraphText>
            </div>
          ) : (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                Fees
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                $0.00
              </ParagraphText>
            </div>
          )}
        </div>
      </div>

      <TotalDueContainer>
        <ParagraphText
          variant="reg"
          style={{fontWeight: 'bold'}}
          className="m-0"
        >
          Total Due Today
        </ParagraphText>

        <ParagraphText
          variant="reg"
          style={{fontWeight: 'bold'}}
          className="m-0"
        >
          {formatPrice(getTodayAmount())}{' '}
          {!paymentState.calculatedTaxes &&
            !isAusNZ &&
            (!frontendPreviewPayment ||
              !paymentState.paymentInfo?.paymentPreview) &&
            ' + tax'}
        </ParagraphText>
      </TotalDueContainer>
      {isAus && (
        <ParagraphText
          variant="sm"
          style={{
            fontSize: '10px',
            color: '#4D4D4D',
            marginTop: '10px',
            marginBottom: 0,
          }}
        >
          *All plans include GST {!isSEOPreviewAvailable && 'except SEO'}
        </ParagraphText>
      )}
    </>
  )
}

export {CCDesktopTodaySummary}
