import React, {useState, useEffect} from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {useAsync} from '../utils/use-async'
import {useAuthClient} from '../utils/use-auth-client'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../utils/constants'

const getTotalNumberPrice = products => {
  let totalPrice = 0
  for (const i in products) {
    if (typeof products[i].price === 'string') {
      totalPrice +=
        parseFloat(products[i].price.replace(',', '').replace('$', '')).toFixed(
          2,
        ) * 1
    } else {
      totalPrice += parseFloat(products[i].price).toFixed(2) * 1
    }
  }
  return totalPrice
}
const PlanComparisonDetails = ({
  accountInfo,
  selectedPlan,
  listOfSelectedAddons,
  discount,
  setCurrentPrice,
}) => {
  const [currentTotal, setCurrentTotal] = useState()
  const [listOfAddons, setListOfAddons] = useState([])
  const [subTotalPrice, setSubTotalPrice] = useState('')
  const {run} = useAsync({
    status: 'pending',
  })
  const client = useAuthClient()

  const filterAddOnType = addOnType =>
    listOfSelectedAddons.filter(({type}) => type === addOnType)

  const addOns = filterAddOnType('addon').filter(
    ({planCode}) => planCode !== '247support-m2m',
  )
  const entitlements = filterAddOnType('entitlements')
  const oneTimeAddOns = filterAddOnType('oneTime')

  useEffect(() => {
    const filters = [
      ({type, billingPreference}) =>
        type === ADDONS_TYPES['ONE_TIME'] ||
        billingPreference === BILLING_PREFERENCES[2],
      ({type, billingPreference}) =>
        type === ADDONS_TYPES['ADD_ON'] &&
        billingPreference !== BILLING_PREFERENCES[2],
      ({type, billingPreference, planCode}) =>
        type === ADDONS_TYPES['SELECTED_PLAN'] &&
        billingPreference &&
        !Array.from(addOns, x => x.planCode).includes(planCode) &&
        !Array.from(entitlements, x => x.planCode).includes(planCode) &&
        !Array.from(oneTimeAddOns, x => x.planCode).includes(planCode),
      ({type, product}) =>
        type === ADDONS_TYPES['SELECTED_PLAN'] && product === 'Onboarding Fee',
    ]

    const subTotal =
      filters.reduce(
        (acc, callBackFn) =>
          acc + getTotalNumberPrice(listOfSelectedAddons.filter(callBackFn)),
        0,
      ) - discount
    const formattedSubTotal = parseFloat(subTotal)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    setSubTotalPrice(formattedSubTotal)
  }, [discount, listOfSelectedAddons, addOns, entitlements, oneTimeAddOns])

  useEffect(() => {
    const getAddonsPrices = async () => {
      const url = `plans/list/all`
      const response = await run(client(url))
      setListOfAddons(response)
    }
    getAddonsPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (accountInfo) {
      setCurrentPrice(accountInfo.subtotal)
      setCurrentTotal(accountInfo.subtotal)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo, listOfAddons.addOns, listOfAddons.oneTimeAddons])

  return (
    <div style={{maxWidth: '380px'}}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          rowGap: '5px',
        }}
      >
        <ParagraphText variant="reg" className="font-weight-bold">
          OLD PLAN:
        </ParagraphText>
        <ParagraphText variant="reg">{accountInfo.planName}</ParagraphText>

        <ParagraphText variant="reg" className="font-weight-bold">
          OLD ADD ONS:
        </ParagraphText>
        <div className="d-flex flex-column">
          {accountInfo?.addOns?.length > 0 &&
            accountInfo.addOns
              .filter(({addOnCode}) => addOnCode !== '247support-m2m')
              .map(({name, quantity}, i) => (
                <ParagraphText
                  variant="reg"
                  key={i}
                  style={{marginBottom: '0'}}
                >
                  {name} (Qty: {quantity})
                </ParagraphText>
              ))}
          {accountInfo?.legacyAddOns?.length > 0 &&
            accountInfo.legacyAddOns.map(({name, quantity}, i) => (
              <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
                {name} (Qty: {quantity})
              </ParagraphText>
            ))}
          {!accountInfo?.addOns?.length > 0 &&
            !accountInfo?.legacyAddOns?.length > 0 && (
              <ParagraphText variant="reg">No add-ons selected</ParagraphText>
            )}
        </div>

        <ParagraphText variant="reg" className="font-weight-bold">
          OLD TOTAL:
        </ParagraphText>
        <ParagraphText variant="reg">
          $
          {currentTotal?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }) || '-'}
        </ParagraphText>
      </div>
      <hr style={{columnSpan: '4'}} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          rowGap: '5px',
        }}
      >
        <ParagraphText variant="reg" className="font-weight-bold">
          NEW PLAN:
        </ParagraphText>
        <ParagraphText variant="reg">
          {['marketingCenter', 'thryvLeads'].includes(selectedPlan.name)
            ? accountInfo.planName
            : selectedPlan.planName}
        </ParagraphText>

        {addOns.length > 0 && (
          <ParagraphText variant="reg" className="font-weight-bold">
            ADDONS:
          </ParagraphText>
        )}
        {addOns.length > 0 && (
          <div className="d-flex flex-column">
            {addOns.map(({product, quantity}, i) => (
              <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
                {product} {!quantity ? '' : `Qty: ${quantity}`}
              </ParagraphText>
            ))}
          </div>
        )}

        {entitlements.length > 0 && (
          <ParagraphText variant="reg" className="font-weight-bold">
            ENTITLEMENTS:
          </ParagraphText>
        )}
        {entitlements.length > 0 && (
          <div className="d-flex flex-column">
            {entitlements.map(({product}, i) => (
              <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
                {product} (Qty: 1)
              </ParagraphText>
            ))}
          </div>
        )}

        {oneTimeAddOns.length > 0 && (
          <ParagraphText variant="reg" className="font-weight-bold">
            ONE TIMES:
          </ParagraphText>
        )}
        {oneTimeAddOns.length > 0 && (
          <div className="d-flex flex-column">
            {oneTimeAddOns.map(({product, quantity}, i) => (
              <ParagraphText variant="reg" key={i} style={{marginBottom: '0'}}>
                {product} {!quantity ? '' : `Qty: ${quantity}`}
              </ParagraphText>
            ))}
          </div>
        )}

        <ParagraphText variant="reg" className="font-weight-bold">
          SUBTOTAL:
        </ParagraphText>
        <ParagraphText variant="reg">${subTotalPrice}</ParagraphText>
      </div>
    </div>
  )
}

export {PlanComparisonDetails}
