import React, {useState} from 'react'
import {Header, Button, Icon, Input, ButtonGroup} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'

function CouponModal({
  close,
  code,
  setCode,
  ccCouponInfo,
  setCcCouponInfo,
  selectedCountry,
  selectedCentersAndApps,
}) {
  const [coupon, setCoupon] = useState(code)
  const client = useAuthClient()

  const handleChange = e => {
    setCoupon(e.target.value)
  }

  const validateCoupon = async () => {
    if (coupon && ccCouponInfo?.code !== coupon) {
      setCoupon(coupon.trim())
      try {
        const codes = []
        for (const key in selectedCentersAndApps) {
          codes.push(...selectedCentersAndApps[key].map(x => x.code))
        }
        let url = `orders/coupon/validate?couponCode=${coupon}&country=${selectedCountry}&addonsString=${codes.join()}`
        const response = await client(url)
        if (response.isValid) {
          toast.success('Coupon applied.')
          setCcCouponInfo({...response, code: coupon.trim()})
          setCode(coupon)
        }
        if (!response.isValid) {
          setCode('')
          toast.error('Invalid coupon.')
        }
      } catch (error) {
        setCode('')
        toast.error('Invalid coupon.')
        if (error.status === 404) {
          setCcCouponInfo({isValid: false})
        }
      }
    }
  }

  const handleSubmit = async () => {
    await validateCoupon()
  }

  return (
    <div
      data-testid="coupon-modal"
      className="d-flex justify-content-between flex-column"
      style={{width: '500px', height: '272px'}}
    >
      <div className="w-100 d-flex align-items-center justify-content-between">
        <Header variant="h2" fontWeight="semibold">
          Code
        </Header>
        <Icon
          style={{cursor: 'pointer'}}
          onClick={close}
          type="regular"
          variant="x"
          height="18"
          width="18"
          color="#A3A5A7"
        />
      </div>
      <Input
        className="w-100"
        type="text"
        placeholder="Code"
        aria-label="code"
        name="code"
        withLabel
        variant="default"
        onChange={handleChange}
        value={coupon}
      />
      <ButtonGroup
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          columnGap: '16px',
        }}
      >
        <Button variant="text" level={1} textVariant="light" onClick={close}>
          CANCEL
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </ButtonGroup>
    </div>
  )
}

export {CouponModal}
