import React, {useEffect} from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../utils/constants'
import {CartProducts} from './cart-products'
import {ADD_ON} from '../utils/addons-data'
import {formatPrice} from '../utils/currency-format'

function CartAddons({
  listOfAddons,
  selectedCountry,
  currentStep,
  accountInfo,
  setOrderTotals,
}) {
  const [totals, setTotals] = React.useState([])
  let addons = []
  let entitlements = []
  let oneTime = []
  const isMarketingCenter = listOfAddons.some(
    addon =>
      addon.type === 'selectedPlan' &&
      ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
        addon.planCode,
      ),
  )
  addons = [
    {
      type: ADDONS_TYPES['ADD_ON'],
      product: 'Addons',
      highlighted: true,
    },
    ...listOfAddons.filter(
      ({type, planCode}) =>
        type === ADDONS_TYPES['ADD_ON'] && planCode !== '247support-m2m',
    ),
  ]
  // const removeDuplicateAddons = (addonList) => {
  //   let uniqueAddonList = {}
  //   // for (let i = 0; i < addonList.length; i++) {
  //   //   if(uniqueAddonList[addonList.])
  //   // }
  // }
  // // console.log({addons})
  // addons = removeDuplicateAddons(addons)
  entitlements = [
    {
      type: ADDONS_TYPES['ENTITLEMENTS'],
      product: 'Entitlements',
      highlighted: true,
    },
    ...listOfAddons.filter(({type}) => type === ADDONS_TYPES['ENTITLEMENTS']),
  ]
  oneTime = [
    {
      type: ADDONS_TYPES['ONE_TIME'],
      product: 'One time',
      highlighted: true,
    },
    ...listOfAddons.filter(({type}) => type === ADDONS_TYPES['ONE_TIME']),
  ]
  const getTotalPrice = products => {
    const _products = products
    let totalPrice = 0
    for (const i in _products) {
      if (typeof _products[i].price === 'string') {
        totalPrice +=
          parseFloat(_products[i].price.replace(/[$,a-zA-Z]/g, '')).toFixed(2) *
          1
      } else {
        totalPrice += parseFloat(_products[i].price).toFixed(2) * 1
      }
    }

    return totalPrice > 0 ? totalPrice : 0
  }

  useEffect(() => {
    const handleTotalPrices = () => {
      const totals = []
      const _products = listOfAddons.filter(
        ({type}) => type !== ADDONS_TYPES['TOTAL'],
      )
      const oldPlan = {
        billingPreference: 'monthly',
        planCode: accountInfo?.planCode,
        price: accountInfo?.planPrice?.toFixed(2).toString(),
        product: accountInfo?.planName,
        type: ADDONS_TYPES['SELECTED_PLAN'],
      }
      const allProducts = isMarketingCenter
        ? _products.filter(({type}) => type !== ADDONS_TYPES['SELECTED_PLAN'])
        : _products
      if (isMarketingCenter) allProducts.push(oldPlan)
      totals.push({
        type: 'total',
        product: 'Subtotal',
        price: `${getTotalPrice(allProducts)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
        highlighted: true,
      })
      let semiAnnuallyTotalPrice = getTotalPrice(
        _products.filter(
          ({billingPreference}) => billingPreference === BILLING_PREFERENCES[1],
        ),
      )
      if (accountInfo?.balanceInfo?.availableCreditAmount) {
        semiAnnuallyTotalPrice =
          semiAnnuallyTotalPrice +
          (accountInfo?.balanceInfo?.availableCreditAmount || 0)
      }
      semiAnnuallyTotalPrice =
        semiAnnuallyTotalPrice > 0 ? semiAnnuallyTotalPrice : 0

      totals.push({
        type: 'total',
        product: 'Credit',
        price: `${formatPrice(
          accountInfo?.balanceInfo?.availableCreditAmount || 0,
        )}`,
        highlighted: true,
      })
      if (semiAnnuallyTotalPrice > 0) {
        totals.push({
          type: 'total',
          product: 'Due Semi-Annually',
          price: `${formatPrice(semiAnnuallyTotalPrice)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          highlighted: true,
        })
      }
      const filteredMonthlyProducts = _products.filter(
        ({billingPreference, planCode, type}) =>
          !isMarketingCenter
            ? billingPreference === BILLING_PREFERENCES[0]
            : type === ADDONS_TYPES['ADD_ON'] &&
              (ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
                planCode,
              ) ||
                ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(
                  planCode,
                )) &&
              billingPreference === BILLING_PREFERENCES[0],
      )
      if (isMarketingCenter) filteredMonthlyProducts.push(oldPlan)
      let monthlyTotalPrice = getTotalPrice(filteredMonthlyProducts)
      if (accountInfo?.balanceInfo?.availableCreditAmount) {
        monthlyTotalPrice =
          monthlyTotalPrice +
          (accountInfo?.balanceInfo?.availableCreditAmount || 0)
      }

      monthlyTotalPrice =
        monthlyTotalPrice > 0 ? formatPrice(monthlyTotalPrice) : formatPrice(0)
      if (monthlyTotalPrice > 0) {
        totals.push({
          type: 'total',
          product: 'Due Monthly',
          price: `${formatPrice(monthlyTotalPrice)}
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
          highlighted: true,
        })
      }

      if (setOrderTotals) setOrderTotals(totals)
      setTotals(totals)
    }
    handleTotalPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfAddons])

  return (
    <div>
      <CartProducts
        listOfProducts={listOfAddons.filter(
          ({type}) => type === ADDONS_TYPES['SELECTED_PLAN'],
        )}
        currentStep={currentStep}
        selectedCountry={selectedCountry}
        currentPlanName={accountInfo?.planName}
        currentPlanPrice={accountInfo?.planPrice}
      />
      {addons.length > 1 && (
        <CartProducts
          listOfProducts={addons}
          currentStep={currentStep}
          selectedCountry={selectedCountry}
        />
      )}
      {entitlements.length > 1 && (
        <CartProducts
          listOfProducts={entitlements}
          currentStep={currentStep}
          selectedCountry={selectedCountry}
        />
      )}
      {oneTime.length > 1 && (
        <CartProducts
          listOfProducts={oneTime}
          currentStep={currentStep}
          selectedCountry={selectedCountry}
        />
      )}
      <CartProducts
        listOfProducts={totals}
        selectedCountry={selectedCountry}
        currentStep={currentStep}
      />
      {selectedCountry === 'AU' ||
        (selectedCountry === 'aus' && (
          <ParagraphText
            variant="sm"
            className="font-weight-bold mt-4
          "
          >
            *All plans include GST except SEO.
          </ParagraphText>
        ))}
    </div>
  )
}

export {CartAddons}
