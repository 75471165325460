import React, {useContext} from 'react'
import {Modal} from '@thryvlabs/maverick'
import {ParagraphText as Text, ModalTitle} from '@thryvlabs/maverick'
import {headerContext} from '../context/header-context'
import {useAuthClient} from '../utils/use-auth-client'
import {toast} from 'react-toastify'

const cancelCreative = async (
  client,
  creativeApptId,
  countryISOValue,
  setCreativeConfirmed,
  setCreativeCallDate,
) => {
  const data = {
    type: 'creative',
    demoApptId: creativeApptId,
    country: countryISOValue,
  }
  try {
    await client('plans/creative', {data, method: 'DELETE'})
    setCreativeConfirmed(false)
    setCreativeCallDate(null)
  } catch {
    toast.error('error cancelling creative')
  }
}

const cancelOnboard = async (
  client,
  kickoffApptId,
  countryISOValue,
  setOnboardingConfirmed,
  setOnboardingCallDate,
) => {
  const data = {
    type: 'onboarding',
    demoApptId: kickoffApptId,
    country: countryISOValue,
  }

  try {
    await client('plans/onboarding', {data, method: 'DELETE'})
    setOnboardingConfirmed(false)
    setOnboardingCallDate(null)
  } catch {
    toast.error('error cancelling onboarding')
  }
}

const ConfirmGoBackModal = ({
  prevStep,
  altButton,
  creativeApptId,
  kickoffApptId,
  countryISOValue,
  setCreativeConfirmed,
  setOnboardingConfirmed,
  setOnboardingCallDate,
  setCreativeCallDate,
}) => {
  const {isHeaderVisible, setIsHeaderVisible} = useContext(headerContext)
  const client = useAuthClient()

  async function handleClick() {
    if (creativeApptId) {
      await cancelCreative(
        client,
        creativeApptId,
        countryISOValue,
        setCreativeConfirmed,
        setCreativeCallDate,
      )
    }
    if (kickoffApptId) {
      await cancelOnboard(
        client,
        kickoffApptId,
        countryISOValue,
        setOnboardingConfirmed,
        setOnboardingCallDate,
      )
    }
    prevStep()
    if (!isHeaderVisible) {
      setIsHeaderVisible(true)
    }
  }

  return (
    <Modal
      id="confirm-go-back-modal"
      variant="default"
      btnText="Confirm"
      btnType="primary"
      btnActionText="CONFIRM"
      btnAction={handleClick}
      footer
      action
      footerCancel
      altBtn={altButton}
    >
      <ModalTitle variant="subtitle" size="lg">
        Are you sure you want to go back?
      </ModalTitle>
      <Text variant="reg">
        If you go back, you will have to reschedule the appointment.
      </Text>
    </Modal>
  )
}

export {ConfirmGoBackModal}
