import {useState, useEffect} from 'react'
import {useAuthClient} from './use-auth-client'
import {useLocation} from 'react-router'
import {
  MC_PLANS,
  BC_PLANS,
  NEW_CUSTOMER_INIT_MC,
  NEW_CUSTOMER_INIT_BC,
  RC_PLANS,
  NEW_RC_COUPON,
} from './constants'
import {ADD_ON} from './addons-data'

const WIN_BACK_4_COUPONS = {
  'plus-4-dexyp-m2m': 'pluswinback2022',
  'unlimited-4-dexyp-m2m': 'unlimitedwinback2022',
  'professional-4-dexyp-m2m': 'professionalwinback2022',
}

function useAutoCoupons({
  selectedPackage,
  selectedIndustryId,
  selectedCountry,
  selectedCentersAndApps,
  defaultCentersAndApps,
  expiredCentersAndApps,
  eligibleTransitionCenters,
  selectedAddons,
  upgradeAccountInfo,
  incentiveCoupon,
  setIncentiveCoupon,
  currentStep,
  setOrderCouponCodes,
  ccCouponInfo,
  upgradePaymentPreview,
  isEaidFound,
  isCohort,
}) {
  const [autoCouponInfo, setAutoCouponInfo] = useState([])
  const [appliedCoupons, setAppliedCoupons] = useState(false)
  const client = useAuthClient()
  const {pathname} = useLocation()

  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12
  const isUpgrade = pathname === '/order/upgrade'
  const selectedPlan = selectedPackage || 'appmarket-5-thryv-m2m'
  const is5Plan = selectedPlan.includes('-5-')
  const FREE_ACCOUNT_CODES = [
    'appmarket-5-thryv-m2m',
    'cc_basicpls-5-thryv-m2m',
    'cc_basic-5-thryv-m2m',
    'addon-5-signatures_usage',
    'app-websitebuilder',
  ]
  const findSensisSiteDomain = () => {
    if (selectedCentersAndApps) {
      return selectedCentersAndApps.apps.find(app => app.sensisSiteDomain)
        ?.sensisSiteDomain
    }
    return selectedAddons?.find(
      x => x.product === ADD_ON.add_ons['Website'].label,
    )?.sensisSiteDomain
  }
  const resetAutoCoupons = () => {
    setAutoCouponInfo([])
    setAppliedCoupons(false)
  }

  useEffect(() => {
    if (
      (currentStep < 6 && !isUpgrade) ||
      (isUpgrade &&
        ((currentStep < 2 && is5Plan) || (currentStep < 3 && !is5Plan)))
    )
      return

    async function fetchCoupon({couponCode, planCode, addonsString}) {
      const baseUrl = 'orders/coupon/validate'
      const addonsQueryString = addonsString
        ? `addonsString=${addonsString}&`
        : ''
      const url = `${baseUrl}?${addonsQueryString}couponCode=${couponCode}&country=${selectedCountry.isoValue}&planCode=${planCode}`
      const coupon = await client(url)
      return coupon
    }

    async function applyAutoCoupons() {
      let isFreeAccountWithoutExpiredSubs = true
      const isFreeAccount = !(
        defaultCentersAndApps?.current?.centers?.some(
          center => !FREE_ACCOUNT_CODES.includes(center.code),
        ) ||
        defaultCentersAndApps?.current?.apps?.some(
          app => !FREE_ACCOUNT_CODES.includes(app.code),
        )
      )
      if (isUpgrade) {
        isFreeAccountWithoutExpiredSubs = !(
          expiredCentersAndApps?.current?.centers?.some(
            center => !FREE_ACCOUNT_CODES.includes(center.code),
          ) ||
          expiredCentersAndApps?.current?.apps?.some(
            app => !FREE_ACCOUNT_CODES.includes(app.code),
          )
        )
      }
      console.log(
        'isFreeAccountWithoutExpiredSubs' + isFreeAccountWithoutExpiredSubs,
      )
      const isRCInCart = selectedCentersAndApps?.centers.some(({code}) =>
        RC_PLANS.includes(code),
      )
      const isMCInCart = selectedCentersAndApps?.centers.some(({code}) =>
        MC_PLANS.includes(code),
      )
      const isBCInCart = selectedCentersAndApps?.centers.some(({code}) =>
        BC_PLANS.includes(code),
      )
      const isRCInDefault = defaultCentersAndApps?.current?.centers.some(
        ({code}) => RC_PLANS.includes(code),
      )
      const isBCInDefault = defaultCentersAndApps?.current?.centers.some(
        ({code}) => BC_PLANS.includes(code),
      )
      const isMCInDefault = defaultCentersAndApps?.current?.centers.some(
        ({code}) => MC_PLANS.includes(code),
      )
      let nciMcCouponRequired = false
      let nciBcCouponRequired = false
      let rcCouponRequired = false
      if (isRCInCart) {
        if (!isRCInDefault) {
          rcCouponRequired = true
        }
      }
      if (isFreeAccount && isFreeAccountWithoutExpiredSubs) {
        if (isBCInCart || isMCInCart) {
          if (isBCInCart && isMCInCart) {
            if (!isBCInDefault && !isMCInDefault) {
              nciBcCouponRequired = true
              nciMcCouponRequired = true
            } else if (!isBCInDefault && isMCInDefault) {
              nciBcCouponRequired = true
            } else if (isBCInDefault && !isMCInDefault) {
              nciMcCouponRequired = true
            } else {
              nciBcCouponRequired = false
              nciMcCouponRequired = false
            }
          } else if (isBCInCart && !isMCInCart) {
            if (isBCInDefault) {
              nciBcCouponRequired = false
            } else {
              nciBcCouponRequired = true
            }
          } else if (isMCInCart && !isBCInCart) {
            if (isMCInDefault) {
              nciMcCouponRequired = false
            } else {
              nciMcCouponRequired = true
            }
          } else {
            nciBcCouponRequired = false
            nciMcCouponRequired = false
          }
        }
      }
      const isWebsiteApp =
        selectedCentersAndApps?.apps.find(
          ({name}) => name === 'Professionally Designed Website',
        ) || selectedAddons?.find(({product}) => product === 'Website')
      const websiteEntitlement =
        selectedCentersAndApps?.centers
          .flatMap(obj => obj.entitlements || [])
          .find(entitlement =>
            ['ent-5-website', 'addon-5-website_difm'].includes(
              entitlement.code,
            ),
          ) || null
      const isTrialPlan = upgradeAccountInfo?.planCode?.includes('trial')
      const sensisDomain = findSensisSiteDomain()
      const addonsString =
        (!selectedCentersAndApps
          ? selectedAddons
              ?.filter(addon => addon.planCode !== undefined)
              ?.map(addon => addon.planCode)
          : [
              ...selectedCentersAndApps.centers,
              ...selectedCentersAndApps.apps,
              ...selectedCentersAndApps.oneTimes,
            ]
              .map(obj => obj.code)
              .join(',')) || ''
      let incentive = incentiveCoupon
      let data

      if (!isNewOrder && upgradeAccountInfo.status !== 'active') {
        const winBackCoupon = WIN_BACK_4_COUPONS[selectedPlan]
        if (winBackCoupon)
          data = {couponCode: winBackCoupon, planCode: selectedPlan}
      } else if (isTrialPlan) {
        data = {
          couponCode: 'transition75',
          planCode: selectedPlan,
        }
      }

      let domainData

      if ((isWebsiteApp?.name || isWebsiteApp?.product) && sensisDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }
      if (websiteEntitlement && websiteEntitlement?.sensisSiteDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }

      const couponInfo = data && (await fetchCoupon({...data, addonsString}))
      const domainCoupon =
        domainData && (await fetchCoupon({...domainData, addonsString}))

      const cohortCouponApplies =
        isCohort &&
        (selectedCentersAndApps?.oneTimes.find(
          fee => fee.code === 'thryvprfee',
        ) ||
          selectedAddons?.find(addon => addon.planCode === 'onboarding-fee'))

      const cohortCoupon =
        cohortCouponApplies &&
        (await fetchCoupon({
          couponCode: 'cohort-waive-onboardingfee',
          addonsString,
        }))

      let autoCouponData = []
      if (nciBcCouponRequired) {
        const {code} = selectedCentersAndApps.centers.find(({code}) =>
          BC_PLANS.some(plan => code.toLowerCase().includes(plan)),
        )
        const createUrl = `orders/coupon/validate?couponCode=${NEW_CUSTOMER_INIT_BC}&country=${selectedCountry.isoValue}&planCode=${code}`
        let couponResponse = await client(createUrl)
        autoCouponData.push(couponResponse)
      }
      if (nciMcCouponRequired) {
        const {code} = selectedCentersAndApps.centers.find(({code}) =>
          MC_PLANS.some(plan => code.toLowerCase().includes(plan)),
        )
        const createUrl = `orders/coupon/validate?couponCode=${NEW_CUSTOMER_INIT_MC}&country=${selectedCountry.isoValue}&planCode=${code}`
        let couponResponse = await client(createUrl)
        autoCouponData.push(couponResponse)
      }
      if (rcCouponRequired) {
        const {code} = selectedCentersAndApps.centers.find(({code}) =>
          RC_PLANS.some(plan => code.toLowerCase().includes(plan)),
        )
        const createUrl = `orders/coupon/validate?couponCode=${NEW_RC_COUPON}&country=${selectedCountry.isoValue}&planCode=${code}`
        let couponResponse = await client(createUrl)
        autoCouponData.push(couponResponse)
      }

      if (incentive) setIncentiveCoupon(couponInfo)

      if (couponInfo) autoCouponData.push(couponInfo)
      if (domainCoupon) autoCouponData.push(domainCoupon)
      if (cohortCoupon) autoCouponData.push(cohortCoupon)

      if (autoCouponData.length > 0) setAutoCouponInfo(autoCouponData)
    }
    applyAutoCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, upgradePaymentPreview])

  useEffect(() => {
    if (
      ((currentStep < 6 && !isUpgrade) ||
        (isUpgrade && ((currentStep < 2 && is5Plan) || !is5Plan))) &&
      appliedCoupons
    )
      return
    setOrderCouponCodes(
      [...autoCouponInfo, ccCouponInfo?.isValid ? ccCouponInfo : null].filter(
        Boolean,
      ),
    )
    setAppliedCoupons(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    ccCouponInfo,
    setOrderCouponCodes,
    currentStep,
    appliedCoupons,
  ])

  return {autoCouponInfo, resetAutoCoupons}
}

export default useAutoCoupons
