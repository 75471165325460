/**
@jsxRuntime
classic */
/** @jsx jsx */
import React from 'react'
import {jsx} from '@emotion/core'
import {
  CategoryContainer,
  ContainerTitle,
  IndustryCategoryItem,
  IndustryCategoryType,
} from '../components/ui/industry-category'
import {useUserRoles} from '../utils/use-user-roles'
import {Icon, Navigation} from '@thryvlabs/maverick'
import {CancelOrderModal} from '../components/cancel-order-modal'
import industryAnimal from '../assets/industry-animal.png'
import industryAutomotive from '../assets/industry-automotive.png'
import industryChildcare from '../assets/industry-childcare.png'
import industryEvents from '../assets/industry-events.png'
import industryHealth from '../assets/industry-health.png'
import industryHome from '../assets/industry-home.png'
import industryLegal from '../assets/industry-legal.png'
import industryRealState from '../assets/industry-real-state.png'
import industrySalons from '../assets/industry-salons.png'
import industryWellness from '../assets/industry-wellness.png'
import industryThryv from '../assets/industry-thryv.png'
import industryWebsite from '../assets/industry-website.png'

const INDUSTRY_CATEGORIES = [
  {
    id: 1,
    name: 'Animal Service',
    logo: industryAnimal,
    description: 'For Veterinarians, Groomers, Pet Care Services and more.',
    type: 'thryv',
  },
  {
    id: 2,
    name: 'Automotive Services',
    logo: industryAutomotive,
    description: 'For Auto Repair, Mechanics, Garages Services and more.',
    type: 'thryv',
  },
  {
    id: 3,
    name: 'Childcare Services',
    logo: industryChildcare,
    description: 'For Childcare, Daycare Services and more.',
    type: 'thryv',
  },
  {
    id: 4,
    name: 'Events',
    logo: industryEvents,
    description:
      'For Catering, Florists, Special Events, Wedding Services and more.',
    type: 'thryv',
  },
  {
    id: 5,
    name: 'Health Services',
    logo: industryHealth,
    description: 'For Dentists, Healthcare Providers and more.',
    type: 'thryv',
  },
  {
    id: 6,
    name: 'Home Services',
    logo: industryHome,
    description: 'For Plumbers, Electricians, HVAC and more.',
    type: 'thryv',
  },
  {
    id: 7,
    name: 'Legal Services',
    logo: industryLegal,
    description: 'For Attorneys, Legal Practices and more.',
    type: 'thryv',
  },
  {
    id: 8,
    name: 'Real Estate',
    logo: industryRealState,
    description: 'For Realtors, Brokers, Agents and more.',
    type: 'thryv',
  },
  {
    id: 9,
    name: 'Salons',
    logo: industrySalons,
    description: 'For Salons, Spas and more.',
    type: 'thryv',
  },
  {
    id: 10,
    name: 'Wellness',
    logo: industryWellness,
    description: 'For Fitness Studios, Physical Therapy and more.',
    type: 'thryv',
  },
  {
    id: 11,
    name: 'Thryv',
    logo: industryThryv,
    description: 'For any Service-Based Business, Niche Providers and more.',
    type: 'thryv',
  },
]

const ALL_CATEGORY_TYPES = [
  {
    name: 'Industry Solution',
    pillVariant: 'secondary',
    alerts: 0,
  },
]

function IndustryCategorySelection({prevStep, nextStep, selectedCountry}) {
  const [categoryType, setCategoryType] = React.useState(0)
  const {roles} = useUserRoles()

  const premiseDemoUser = roles.includes('PremiseDemo')
  const noCountrySelection = roles.some(role =>
    ['VivialDemo', 'reseller', 'ThryvOnly'].includes(role),
  )
  const [industryCategories, setIndustryCategories] =
    React.useState(INDUSTRY_CATEGORIES)

  const changeIndustryCategoryType = index => {
    setCategoryType(index)
    setIndustryCategories(INDUSTRY_CATEGORIES)
  }

  const startOrder = (id, type) => {
    nextStep({industryCategoryId: id, industryCategoryType: type})
  }

  React.useEffect(() => {
    if (['AU', 'CA'].includes(selectedCountry)) {
      const industryCategoryType = [
        {
          id: 13,
          name: 'Sync Thryv Website',
          logo: industryWebsite,
          type: 'sync_website',
        },
      ]
      if (selectedCountry === 'CA' && premiseDemoUser) {
        setIndustryCategories(industryCategoryType)
        return
      }
      if (categoryType !== 0) {
        setIndustryCategories(industryCategoryType)
      }
    }
  }, [selectedCountry, categoryType, premiseDemoUser])

  return (
    <div
      className="wrapper py-0 w-100 h-100"
      style={{paddingTop: '0 !important'}}
    >
      {!noCountrySelection && (
        <Icon
          type="solid"
          variant="arrowLeft"
          className="back__arrow mb-5 ml-4 mt-2"
          style={{cursor: 'pointer'}}
          onClick={prevStep}
        />
      )}
      <div
        className={
          noCountrySelection
            ? 'd-flex justify-content-center align-content-center mt-2'
            : 'd-flex justify-content-center align-content-center'
        }
      >
        <div className={noCountrySelection ? 'col-md-9 mt-5' : 'col-md-9'}>
          <ContainerTitle
            fontWeight="medium"
            className="text-center"
            variant="h2"
            text="Prospecting Tools"
          >
            Industry Solution
            <p style={{fontSize: '15px'}} className={'mt-2'}>
              We’ve tailored the Thryv experience to fit the industries below.
            </p>
          </ContainerTitle>

          <IndustryCategoryType>
            <Navigation
              selected={categoryType}
              setSelectedOption={changeIndustryCategoryType}
              variant="tabs"
              xAxis="center"
              linksArray={ALL_CATEGORY_TYPES}
            ></Navigation>
          </IndustryCategoryType>

          <CategoryContainer>
            {industryCategories.map(({id, name, logo, description, type}) => (
              <IndustryCategoryItem key={id}>
                <div className="image">
                  <img
                    className={
                      type === 'thryv' ? 'image-solution' : 'image-sync'
                    }
                    src={logo}
                    alt={name}
                  />
                  {type === 'thryv' && <span>{name}</span>}
                </div>
                {description && (
                  <p className="category-description">{description}</p>
                )}
                <button
                  data-testid={`start-order-#${id}`}
                  className="order-button"
                  onClick={() => startOrder(id, type)}
                >
                  Start Order
                  <Icon
                    type="solid"
                    variant="arrowRight"
                    color="#FF5000"
                    height="12"
                    width="12"
                  />
                </button>
              </IndustryCategoryItem>
            ))}
          </CategoryContainer>
          <div className="d-flex justify-content-center">
            <CancelOrderModal cancelUrl="/orders" />
          </div>
        </div>
      </div>
    </div>
  )
}

export {IndustryCategorySelection}
