import {ParagraphText} from '@thryvlabs/maverick'
import React from 'react'
import {useLocation} from 'react-router'
import dayjs from 'dayjs'
import {toast} from 'react-toastify'
import {ADD_ON} from '../../utils/addons-data'
import {useAuthClient} from '../../utils/use-auth-client'
import CallSection from './call-section'
import {useUserRoles} from '../../utils/use-user-roles'

const ONBOARDING_REQUIRED_4PLAN_UPGRADES = [
  'addon-4-mc-m2m',
  'addon-4-mc-semi',
  'addon-4-mcpro-m2m',
]

const RADIO_LANGUAGE_PREFERENCES = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

export default function BookAppointments({
  selectedCentersAndApps,
  saveCallData,
  setDisplayCreativeCallModal,
  setDisplayKickOffCall,
  setOnboardingConfirmed,
  setCreativeConfirmed,
  setIsApptSkipped,
  selectedCountry,
  selectedAddons,
  selectedIndustryId,
  onboardingCallDate,
  useAccountOwnerInfo,
  accountInformation,
  isApptSkipped,
  setKickoffApptId,
  kickoffApptId,
  setCreativeApptId,
  creativeApptId,
  setOnboardingCallDate,
  creativeCallDate,
  setCreativeCallDate,
  onboardingConfirmed,
  creativeConfirmed,
  displayKickOffCall,
  displayCreativeCallModal,
  isSyncUpgrade,
  input,
  purchaseIntent,
  industryCategoryType,
  useFormData,
  isKickoffNotRequiredCC,
  upgradeAccountInfo,
  defaultCentersAndApps,
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedKickoffService, setSelectedKickoffService] =
    React.useState(null)
  const [selectedCreativeService, setSelectedCreativeService] =
    React.useState(null)
  const [kickoffLanguagePreference, setKickoffLanguagePreference] =
    React.useState(RADIO_LANGUAGE_PREFERENCES[1])
  const [creativeLanguagePreference, setCreativeLanguagePreference] =
    React.useState(RADIO_LANGUAGE_PREFERENCES[1])
  const [noneCustomVideoCreativeItem, setNoneCustomVideoCreativeItem] =
    React.useState(false)
  const [isFormReady, setIsFormReady] = React.useState(false)
  const {centers} = selectedCentersAndApps || {}
  const {pathname} = useLocation()
  const {roles} = useUserRoles()

  const isInputInvalid = () => {
    return (
      !input.firstNameInput ||
      !input.lastNameInput ||
      !input.emailInput ||
      !input.mobileInput
    )
  }

  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12

  const isThryvLeadsOrSeoIncluded =
    centers?.some(({name}) => name === 'Thryv Leads') ||
    centers?.some(
      ({name}) =>
        name.includes('SEO') &&
        name !== 'SEO' &&
        name !== 'SEO Keywords' &&
        name !== 'SEO PowerBoost',
    )

  const isUpgrade = pathname === '/order/upgrade'
  const isRestrictedAccessHIPPASelected = selectedCentersAndApps?.apps?.some(
    product =>
      product.name === ADD_ON.add_ons['Restricted Access'].label ||
      product.name === ADD_ON.add_ons['HIPAA'].label,
  )
  const isMCSelected = selectedCentersAndApps?.centers?.some(
    product =>
      product.name === 'Marketing Center Pro' ||
      product.name === 'Marketing Center Plus',
  )
  const hasBCSelected = selectedCentersAndApps?.centers?.some(
    product =>
      product.name === 'Business Center Professional' ||
      product.name === 'Business Center Plus' ||
      product.name === 'Business Center Unlimited',
  )
  const hasCCSelected = selectedCentersAndApps?.centers?.some(
    product =>
      product.name === 'Command Center Pro' ||
      product.name === 'Command Center Basic+' ||
      product.name === 'Command Center Plus',
  )
  const isUsSpanishOptionAvailable =
    selectedCountry.isoValue === 'US' && hasBCSelected && hasCCSelected

  const skipAppointment = callType => {
    setIsApptSkipped(prev => ({...prev, [callType]: true}))
  }

  const getLanguagePreference = callType => {
    if (callType === 'kickoff') {
      return kickoffLanguagePreference.value ? 'es' : 'en'
    }
    if (callType === 'creative') {
      return creativeLanguagePreference.value ? 'es' : 'en'
    }
  }

  const getCreativeCallMinDate = () => {
    const _date = onboardingCallDate?.ISODate
      ? new Date(onboardingCallDate?.ISODate)
      : new Date()
    _date.setDate(_date.getDate() + 1)
    return dayjs(_date).toISOString()
  }

  const client = useAuthClient()

  const confirmOnboard = async () => {
    try {
      if (isInputInvalid()) {
        useFormData.trigger(['firstName', 'lastName', 'email', 'mobile'])
        return
      }
      useFormData.clearErrors()
      setIsLoading(true)
      const {apptId} = await client('plans/onboarding', {
        data: {
          firstName: useAccountOwnerInfo
            ? accountInformation.firstName
            : input.firstNameInput,
          lastName: useAccountOwnerInfo
            ? accountInformation.lastName
            : input.lastNameInput,
          email: useAccountOwnerInfo
            ? accountInformation.email
            : input.emailInput,
          phone: useAccountOwnerInfo
            ? accountInformation.mobile
            : input.mobileInput,
          company: accountInformation.company,
          onboarding: {
            timeZone: onboardingCallDate.timeZoneId,
            serviceId: onboardingCallDate.selectedServiceId,
            staffId: onboardingCallDate.selectedStaffId,
            startTime: onboardingCallDate.ISODate,
            thryvBookingDate: onboardingCallDate.startDateTime,
            isAus: accountInformation.country === 'AU',
            isNZ: accountInformation.country === 'NZ',
            country: accountInformation.country,
            isApptHold: true,
          },
        },
      })
      setIsLoading(false)
      setKickoffApptId(apptId)
      setOnboardingConfirmed(true)
      toast.success('Thryv Kickoff Call Confirmed')
    } catch (responseError) {
      setIsLoading(false)
      if (responseError.status !== 400) {
        throw responseError
      }
      responseError.errors.forEach(({message}) => toast.error(message))
    }
  }

  const resetOnboard = () => {
    setOnboardingCallDate(null)
    setKickoffApptId(null)
    resetCreative()
  }

  const getSelectedAddons = () => {
    let checkList = []
    if (selectedCentersAndApps) {
      let allSelectedCentersAndApps = [
        ...selectedCentersAndApps.centers,
        ...selectedCentersAndApps.apps,
        ...selectedCentersAndApps.oneTimes,
      ]
      checkList = allSelectedCentersAndApps
        .filter(({alreadyIncluded}) => !alreadyIncluded)
        .flatMap(item => {
          let entNames = []
          if (isNewOrder) {
            entNames = item.entitlements
              ? item.entitlements.map(e => e.name)
              : []
            return [item.name, ...entNames]
          }
          if (item.entitlements?.length > 0) {
            const newEntitlements = item.entitlements.filter(
              ({alreadyIncluded}) => !alreadyIncluded,
            )
            entNames = newEntitlements.map(e => e.name)
          }
          return [item.name, ...entNames]
        })
    } else {
      const filteredSelectedAddons = selectedAddons?.filter(
        ({planCode, product}) =>
          !upgradeAccountInfo.addOns.some(
            ({addOnCode, name}) => addOnCode === planCode || name === product,
          ),
      )
      checkList = filteredSelectedAddons?.map(({product}) => product)
    }
    return checkList
  }

  React.useEffect(() => {
    const checkList = getSelectedAddons()
    const handleDisplayCreativeCallModal = () => {
      const _addons = [
        ADD_ON.add_ons['Additional Video Views'].label,
        ADD_ON.add_ons['Website'].label,
        ADD_ON.one_time['Logo'].label,
        ADD_ON.one_time['5 Extra Website Pages'].label,
        ADD_ON.one_time['Photo Package'].label,
        ADD_ON.entitlements['Social Content Generation'].label,
        ADD_ON.entitlements['Custom Video'].label,
        'Professionally Designed Website',
        'Professional Designed Logo',
        '5 Extra Website Pages',
      ]
      setNoneCustomVideoCreativeItem(
        handleOtherThanCustomVideo(_addons, checkList),
      )
      return checkList?.some(item => _addons.includes(item)) || false
    }
    const result = handleDisplayCreativeCallModal()

    setDisplayCreativeCallModal(result)
    useFormData.setValue('showCreativeCall', result)

    if (selectedIndustryId === 13) {
      setDisplayKickOffCall(false)
      setDisplayCreativeCallModal(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAddons,
    useFormData.setValue,
    roles,
    selectedIndustryId,
    selectedCentersAndApps,
  ])

  const is4MCSelected = selectedAddons?.some(({planCode}) =>
    ONBOARDING_REQUIRED_4PLAN_UPGRADES.includes(planCode),
  )
  const isMCAlreadyIncluded =
    !selectedCentersAndApps &&
    upgradeAccountInfo?.addOns.some(({addOnCode}) =>
      ONBOARDING_REQUIRED_4PLAN_UPGRADES.includes(addOnCode),
    )
  const isOnboardingRequired4Upgrade =
    isUpgrade && is4MCSelected && !isMCAlreadyIncluded

  const shouldEnableCreativeCallModal = () => {
    if (isNewOrder && (isKickoffNotRequiredCC || isApptSkipped.kickoff)) {
      return true
    }
    if (!isNewOrder && displayKickOffCall === false) {
      return true
    }
    if (isOnboardingRequired4Upgrade && !onboardingConfirmed) {
      return false
    }
    if (isUpgrade && !isSyncUpgrade && !selectedCentersAndApps) {
      return true
    }
    return (
      (purchaseIntent.firstPurchaseIntent &&
        purchaseIntent.secondPurchaseIntent &&
        purchaseIntent.thirdPurchaseIntent &&
        onboardingConfirmed) ||
      isApptSkipped.kickoff
    )
  }

  const handleOtherThanCustomVideo = (list, selected) => {
    const newList = list.filter(
      x => x !== ADD_ON.entitlements['Custom Video'].label,
    )

    return selected?.some(_item => newList.includes(_item))
  }

  const confirmCreative = async () => {
    try {
      if (!creativeCallDate && selectedCentersAndApps) return
      if (isInputInvalid()) {
        useFormData.trigger(['firstName', 'lastName', 'email', 'mobile'])
        return
      }
      useFormData.clearErrors()
      setIsLoading(true)
      const {apptId} = await client('plans/creative', {
        data: {
          firstName: useAccountOwnerInfo
            ? accountInformation.firstName
            : input.firstNameInput,
          lastName: useAccountOwnerInfo
            ? accountInformation.lastName
            : input.lastNameInput,
          email: useAccountOwnerInfo
            ? accountInformation.email
            : input.emailInput,
          phone: useAccountOwnerInfo
            ? accountInformation.mobile
            : input.mobileInput,
          company: accountInformation.company,
          creative: {
            timeZone: creativeCallDate.timeZoneId,
            serviceId: creativeCallDate.selectedServiceId,
            staffId: creativeCallDate.selectedStaffId,
            startTime: creativeCallDate.ISODate,
            thryvBookingDate: creativeCallDate.startDateTime,
            isAus: accountInformation.country === 'AU',
            isNZ: accountInformation.country === 'NZ',
            country: accountInformation.country,
            isApptHold: true,
          },
        },
      })
      setIsLoading(false)
      setCreativeApptId(apptId)
      setCreativeConfirmed(true)
      toast.success('Creative Call Confirmed')
    } catch (responseError) {
      setIsLoading(false)
      if (responseError.status !== 400) {
        throw responseError
      }
      responseError.errors.forEach(({message}) => toast.error(message))
    }
  }

  const resetCreative = () => {
    setCreativeCallDate(null)
    setCreativeApptId(null)
    setCreativeConfirmed(false)
  }

  const cancelAppointment = async (callType, id) => {
    if (callType === 'kickoff') {
      setKickoffApptId(null)
      if (isApptSkipped.kickoff) {
        setIsApptSkipped(prev => ({...prev, kickoff: false}))
        return
      }
      setOnboardingConfirmed(false)
      setOnboardingCallDate(null)
    } else if (callType === 'creative') {
      setCreativeApptId(null)
      if (isApptSkipped.creative) {
        setIsApptSkipped(prev => ({...prev, creative: false}))
        return
      }
      setCreativeConfirmed(false)
      setCreativeCallDate(null)
    }

    const data = {
      type: callType === 'kickoff' ? 'onboarding' : 'creative',
      demoApptId: id,
      country: selectedCountry.isoValue,
    }

    try {
      const url =
        callType === 'creative' ? 'plans/creative' : 'plans/onboarding'
      await client(url, {data, method: 'DELETE'})
    } catch {
      toast.error('error cancelling appt')
    }
  }

  const includeAddonsCodes = (url, callType) => {
    const existingCentersAndApps = defaultCentersAndApps
      ? [
          ...defaultCentersAndApps.centers.map(center => ({
            ...center,
            planType: 'center',
          })),
          ...defaultCentersAndApps.apps,
        ]
      : []

    const allCentersAndApps =
      selectedCentersAndApps &&
      [
        ...existingCentersAndApps,
        ...selectedCentersAndApps.centers,
        ...selectedCentersAndApps.apps,
        ...selectedCentersAndApps.centers
          .filter(({name}) => !name.includes('Command Center'))
          .flatMap(item => item.entitlements),
        ...selectedCentersAndApps.oneTimes.filter(
          ({name}) => name === ADD_ON.entitlements['Custom Video'].label,
        ),
      ]
        .filter((currentItem, currentIndex, entireArray) => {
          const firstIndexWithSameCode = entireArray.findIndex(
            item => item.code === currentItem.code,
          )

          return currentIndex === firstIndexWithSameCode
        })
        .filter(({alreadyIncluded}) => !alreadyIncluded)

    const onboardingAddons = ['HIPAA', 'Restricted Access']

    if (callType === 'kickoff') {
      onboardingAddons.push(
        'Thryv Leads',
        'Marketing Center',
        'SEO Money Back Guarantee',
        'SEO Non-Guaranteed',
        'SEO',
      )
      if (selectedCentersAndApps) onboardingAddons.push('Business Center')
    }

    if (callType === 'creative') {
      if (selectedCentersAndApps) {
        onboardingAddons.push('Custom Video')
        onboardingAddons.push('SEO')
        onboardingAddons.push('Professionally Designed Website')
      } else if (
        selectedAddons &&
        selectedAddons.find(item => item.product === 'SEO')
      ) {
        onboardingAddons.push('SEO')
      }
    }

    const getCodes = (items, nameAccessor) =>
      items.reduce((codes, item) => {
        const name = nameAccessor(item)
        if (onboardingAddons.includes(name)) {
          codes.push(name)
        }
        return codes
      }, [])

    const codes = selectedCentersAndApps
      ? getCodes(allCentersAndApps, item => {
          const isCenter = item?.planType === 'center'
          return item.name.toLowerCase().includes('marketing') && isCenter
            ? 'Marketing Center'
            : item.name.toLowerCase().includes('business') && isCenter
            ? 'Business Center'
            : item.name
        })
      : getCodes(selectedAddons, item =>
          item.product.toLowerCase().includes('marketing center')
            ? 'Marketing Center'
            : item.product,
        )

    return codes.length > 0 ? `${url}&addons=${codes.join(',')}` : url
  }

  React.useEffect(() => {
    if (isFormReady) {
      useFormData.trigger(['firstName', 'lastName', 'email', 'mobile'])
    } else {
      setIsFormReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    input.firstNameInput,
    input.lastNameInput,
    input.emailInput,
    input.mobileInput,
    isFormReady,
  ])

  return (
    <div className="pl-4">
      {(displayKickOffCall || displayCreativeCallModal) && (
        <ParagraphText variant="lg" className="font-weight-bold">
          {selectedCentersAndApps ? 'Schedule Call' : 'SCHEDULE CALLS'}
        </ParagraphText>
      )}
      <div
        className={`${selectedCentersAndApps && 'd-block pr-4'}`}
        style={{columnGap: '20px'}}
      >
        {displayKickOffCall && (
          <CallSection
            apptData={{
              callType: 'kickoff',
              callDate: onboardingCallDate,
              callConfirmed: onboardingConfirmed,
              languagePreference: kickoffLanguagePreference,
              setSelectedService: setSelectedKickoffService,
              selectedService: selectedKickoffService,
              isApptSkipped: isApptSkipped.kickoff,
              cancelAppointment: cancelAppointment,
              confirmAppointment: confirmOnboard,
              resetAppointment: resetOnboard,
              skipAppointment: skipAppointment,
              apptId: kickoffApptId,
              saveCallData: saveCallData,
              setLanguagePreference: setKickoffLanguagePreference,
              getLanguagePreference: getLanguagePreference,
              industryCategoryType: industryCategoryType,
              displayKickOffCall,
              displayCreativeCallModal,
              isOnboardingRequired4Upgrade,
              country: selectedCountry.isoValue,
            }}
            selectedCentersAndApps={selectedCentersAndApps}
            isUpgrade={isUpgrade}
            isSyncUpgrade={isSyncUpgrade}
            isLoading={isLoading}
            shouldEnableCreativeCallModal={shouldEnableCreativeCallModal}
            includeAddonsCodes={includeAddonsCodes}
            accountInformation={accountInformation}
            isMCSelected={isMCSelected}
            isRestrictedAccessHIPPASelected={isRestrictedAccessHIPPASelected}
            noneCustomVideoCreativeItem={noneCustomVideoCreativeItem}
            isNewOrder={isNewOrder}
            isThryvLeadsOrSeoIncluded={isThryvLeadsOrSeoIncluded}
            isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
            errors={useFormData.errors}
          />
        )}
        {displayCreativeCallModal && (
          <CallSection
            apptData={{
              callType: 'creative',
              callDate: creativeCallDate,
              callConfirmed: creativeConfirmed,
              languagePreference: creativeLanguagePreference,
              setSelectedService: setSelectedCreativeService,
              selectedService: selectedCreativeService,
              isApptSkipped: isApptSkipped.creative,
              cancelAppointment: cancelAppointment,
              confirmAppointment: confirmCreative,
              resetAppointment: resetCreative,
              skipAppointment: skipAppointment,
              apptId: creativeApptId,
              saveCallData: saveCallData,
              setLanguagePreference: setCreativeLanguagePreference,
              getLanguagePreference: getLanguagePreference,
              industryCategoryType: industryCategoryType,
              displayKickOffCall,
              displayCreativeCallModal,
              isOnboardingRequired4Upgrade,
              country: selectedCountry.isoValue,
            }}
            defaultDate={getCreativeCallMinDate}
            selectedCentersAndApps={selectedCentersAndApps}
            isUpgrade={isUpgrade}
            isSyncUpgrade={isSyncUpgrade}
            isLoading={isLoading}
            shouldEnableCreativeCallModal={shouldEnableCreativeCallModal}
            includeAddonsCodes={includeAddonsCodes}
            accountInformation={accountInformation}
            isRestrictedAccessHIPPASelected={isRestrictedAccessHIPPASelected}
            noneCustomVideoCreativeItem={noneCustomVideoCreativeItem}
            isNewOrder={isNewOrder}
            isThryvLeadsOrSeoIncluded={isThryvLeadsOrSeoIncluded}
            isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
            register={useFormData.register}
            errors={useFormData.errors}
            onboardingCallDate={onboardingCallDate}
          />
        )}
      </div>
    </div>
  )
}
