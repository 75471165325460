import React, {useState} from 'react'
import {
  Header,
  RadioGroup,
  Input,
  ParagraphText,
  Button,
  Icon,
} from '@thryvlabs/maverick'
import {useAuthClient} from '../utils/use-auth-client'
import {AccountInfo} from '../components/account-info'
import {PlanItem} from '../components/ui/package-selection'
import syncPlanIcon from '../assets/syncPlanIcon.png'
import unlimitedPlanIcon from '../assets/unlimitedPlanIcon.png'
import professionalPlanIcon from '../assets/professionalPlanIcon.png'
import legacyIcon from '../assets/legacyIcon.png'
import {BILLING_PREFERENCES} from '../utils/constants'
import {NewOrderLayout} from '../components/new-order-layout'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useUserRoles} from '../utils/use-user-roles'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {toast} from 'react-toastify'
import {ADD_ON} from '../utils/addons-data'
import {useFlags} from 'launchdarkly-react-client-sdk'
import AsyncSelect from 'react-select/async'

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'
const SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'
const SST_SALES_REP_INFO_NOT_FOUND = 'Sales rep not Found.'

const RADIO_BILLING_PREFERENCES = [
  {name: 'Monthly', value: BILLING_PREFERENCES[0]},
  {name: 'Semi-Annually', value: BILLING_PREFERENCES[1]},
]

let DIFFERENT_SALES_REP_OPTIONS = []

const OVERRIDE_SST_OPTIONS = [
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

const PLANS_ICONS = [
  {
    planName: 'sync',
    icon: syncPlanIcon,
  },
  {
    planName: 'plus',
    icon: syncPlanIcon,
  },
  {
    planName: 'unlimited',
    icon: unlimitedPlanIcon,
  },
  {
    planName: 'professional',
    icon: professionalPlanIcon,
  },
  {
    planName: 'thryv leads',
    icon: legacyIcon,
  },
  {
    planName: 'marketing center plus',
    icon: legacyIcon,
  },
]

const PLAN_INDUSTRY_TYPES = {
  sync: 'sync', // placeholder
  plus: 'thryv',
  professional: 'thryv',
  unlimited: 'thryv',
  'thryv leads': 'sync_leads',
  'marketing center plus': 'thryv',
  'marketing center pro': 'thryv',
}

const THRYV_PLANS = ['plus', 'professional', 'unlimited']

const HAS_MC_FREE = ['addon-4-marketingcenter-freetrial']

const UPDATE_ADDRESS_PLANS = ['legacy', 'freetrial', 'sensisconnect']

const STARTER_PLANS = [
  'successpack',
  'demo',
  'starter',
  'tpfreemium',
  'adapttrial',
  'adapt',
]

const DISPLAY_MC_SYNC_PLANS = ['premium', 'basic']

const SCHEMA = yup.object().shape({
  leadIDRequired: yup.boolean(),
  leadID: yup.string().when('leadIDRequired', leadIDRequired => {
    if (leadIDRequired === true) {
      return yup
        .string()
        .required(REQUIRED_FIELD_MESSAGE)
        .min(3, MIN_CHARACTERS_REQUIRED)
        .max(120, MAX_CHARACTERS_REQUIRED)
    }
    return yup.string()
  }),
  salesRepInfo: yup.string().when('differentSalesRep', {
    is: differentSalesRep => differentSalesRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
  overrideRepInfo: yup.string().when('overrideRep', {
    is: overrideRep => overrideRep.value === 'true',
    then: yup.string().required(REQUIRED_FIELD_MESSAGE),
  }),
})

const findPlanIndex = (planName, listOfPlans) => {
  if (planName === 'pro') {
    return 1
  }
  const planIndex = listOfPlans.findIndex(plan =>
    Object.values(plan).some(val => val?.includes(planName)),
  )
  return planIndex
}

const formatPlanName = planName => {
  if (planName.includes(' (6 mo. Term)')) {
    return planName.replace(' (6 mo. Term)', '').toLowerCase()
  }
  if (planName.includes(' (12 mo. Term)')) {
    return planName.replace(' (12 mo. Term)', '').toLowerCase()
  }
  return planName.toLowerCase()
}

function PlanContent({name, amount, interval}) {
  if (name === 'thryvLeads' || name === 'marketingCenter') {
    return <p className="m-0">{name === 'thryvLeads' ? 'ADD/EDIT' : 'ADD'}</p>
  }
  return (
    <p className="m-0">
      <strong>${amount}</strong> / {interval === 'semiAnnually' && '6'} mo
    </p>
  )
}

function PlanOption({
  planName,
  amount,
  interval,
  selectedPlan,
  setSelectedPlan,
  img,
  onboarding,
  name,
  testid,
  currentPlanIndex,
  listOfPlans,
  addOns,
  hasMC,
  hasMCFree,
  isV3Plan,
  industryType,
  accountInfo,
  isSubCanceled,
  planCode,
  displaySyncAndMCPlans,
  isStarterPlan,
}) {
  amount = String(amount)
  let planIndex = findPlanIndex(planName, listOfPlans)
  const hasTL = addOns?.some(addOn => addOn.name === 'Thryv Leads')
  const showMC = !hasMC && !hasMCFree && !hasTL
  const noMC = !showMC && name === 'marketingCenter'
  const noTL = (hasMC || hasMCFree) && name === 'thryvLeads'

  const isOldLegacyThryv =
    isV3Plan && !THRYV_PLANS.some(plan => accountInfo.planCode.includes(plan))

  const isPlanAvailable =
    planIndex <= currentPlanIndex ||
    name === 'thryvLeads' ||
    name === 'marketingCenter' ||
    displaySyncAndMCPlans ||
    isOldLegacyThryv ||
    isSubCanceled ||
    isStarterPlan

  const isActive =
    selectedPlan?.planName === planName ||
    (planName === 'Professional' &&
      selectedPlan?.planName === 'Pro (12 mo. Term)')
  const isHidden = noTL || noMC
  return (
    <PlanItem
      data-testid={testid}
      className={`
        ${isHidden ? 'd-none' : 'd-flex'}
        justify-content-between align-items-center w-100 mb-3 ${
          isActive ? 'active' : ''
        }`}
      onClick={() => {
        if (!isPlanAvailable) return
        setSelectedPlan({
          planName,
          amount,
          onboarding,
          name,
          industryType,
          planCode,
        })
      }}
      style={{
        backgroundColor: !isPlanAvailable ? '#f9f9f9' : '',
        color: !isPlanAvailable ? '#cecece' : '',
        cursor: !isPlanAvailable ? 'default' : 'pointer',
      }}
    >
      <div className="d-flex align-items-center">
        <img src={img} alt="plan" height={55} width={45} className="mr-2" />
        <p className="m-0">{planName}</p>
      </div>
      {isPlanAvailable ? (
        <PlanContent name={name} amount={amount} interval={interval} />
      ) : (
        <p className="m-0">Unavailable</p>
      )}
    </PlanItem>
  )
}

const UpgradePackageSelection = ({
  prevStep,
  nextStep,
  selectedCountry,
  accountInfo,
  updateBillingAddress,
}) => {
  const [listOfPlans, setListOfPlans] = React.useState({
    plans: [],
    additionalData: [],
  })
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)
  const [selectedOverrideRep, setSelectedOverridRep] = useState(null)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [selectedPlan, setSelectedPlan] = React.useState()
  const [listOfPlansPrices, setListOfPlansPrices] = React.useState([])
  const [billingPreference, setBillingPreference] = React.useState(() =>
    accountInfo.planCode?.includes('-semi')
      ? RADIO_BILLING_PREFERENCES[1]
      : RADIO_BILLING_PREFERENCES[0],
  )
  const [isLoading, setIsLoading] = React.useState(true)
  const [pastDueError, setPastDueError] = React.useState(false)
  const client = useAuthClient()
  const {roles} = useUserRoles()
  console.log('User roles ::', roles)
  if (
    roles.includes('PremisePilot') ||
    roles.includes('LeadConverter') ||
    roles.includes('Reseller') ||
    roles.includes('Admin')
  ) {
    DIFFERENT_SALES_REP_OPTIONS = [
      {name: 'Sales Rep', value: true},
      {name: 'Partner', value: false},
      {name: 'No', value: 'NO'},
    ]
  } else {
    DIFFERENT_SALES_REP_OPTIONS = [
      {name: 'Sales Rep', value: true},
      {name: 'No', value: 'NO'},
    ]
  }
  const {frontendOverrideSst} = useFlags()
  const {
    register,
    // formState: {},
    control,
    watch,
    trigger,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      differentSalesRep:
        roles.includes('PremisePilot') ||
        roles.includes('LeadConverter') ||
        roles.includes('Reseller') ||
        roles.includes('Admin')
          ? [
              {name: 'Sales Rep', value: true},
              {name: 'Partner', value: false},
              {name: 'No', value: 'NO'},
            ][2]
          : [
              {name: 'Sales Rep', value: true},
              {name: 'No', value: 'NO'},
            ][1],
      overrideRep: OVERRIDE_SST_OPTIONS[1],
      xpCode: accountInfo?.xpCode || '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {componentProps: {selectedCountry}},
  })
  const navigate = useNavigate()

  const fetchNameOptions = async (inputValue, country) => {
    let sftoken = sessionStorage.getItem('sfToken') || ''
    if (!country) {
      country = 'US'
    }
    if (sftoken.length > 0) {
      sftoken = JSON.parse(sftoken)
      sftoken = country === 'AU' ? sftoken.ausToken : sftoken.nonAusToken
    }
    const data = await client(
      `salesforce/services/fetchUsers?token=${sftoken}&searchTerm=${inputValue}&country=${country}`,
    )
    const filterData = data.data.filter(item =>
      item.Name.toLowerCase().includes(inputValue.toLowerCase()),
    )
    return filterData.map(item => ({
      label: item.Name,
      value: item.Name,
      salesRepInfo: item,
    }))
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue, selectedCountry).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }
  const handleSalesRepOptionChange = selectedOption => {
    setSelectedSalesRep(selectedOption)
    const salesRepInfo = selectedOption.salesRepInfo
    try {
      setValue('salesRepCode', salesRepInfo.FederationIdentifier)

      setValue('salesRepInfo', `${salesRepInfo.Name}`)
      setValue('behalfFirst', salesRepInfo.FirstName)
      setValue('behalfLast', salesRepInfo.LastName)
      setValue('behalfEmail', salesRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('salesRepInfo', SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('salesRepInfo')
  }

  const [
    differentSalesRep,
    salesRepCode,
    salesRepInfo,
    behalfFirst,
    behalfLast,
    behalfEmail,
    overrideRep,
    overrideRepCode,
    overrideRepInfo,
    overrideRepEmail,
    overrideRepFirst,
    overrideRepLast,
    xpCode,
  ] = watch([
    'differentSalesRep',
    'salesRepCode',
    'salesRepInfo',
    'behalfFirst',
    'behalfLast',
    'behalfEmail',
    'overrideRep',
    'overrideRepCode',
    'overrideRepInfo',
    'overrideRepEmail',
    'overrideRepFirst',
    'overrideRepLast',
    'xpCode',
  ])

  const isTSS = getAuthByRole(['tss'], roles)
  const isV3Plan = accountInfo?.planCode?.includes('-3-')
  const isLegacyPlan = accountInfo?.planCode?.includes('-legacy')
  const planNeedsUpdate = UPDATE_ADDRESS_PLANS.some(code =>
    accountInfo?.planCode?.includes(code),
  )
  const isSyncPlan = accountInfo?.planCode?.includes('sync')
  const hasMC = accountInfo?.addOns?.some(addOn =>
    ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
      addOn.addOnCode,
    ),
  )
  const hasMCFree = accountInfo.addOns?.some(addOn =>
    HAS_MC_FREE.includes(addOn.addOnCode),
  )
  const showThryvLeads = isV3Plan && !hasMC && !hasMCFree
  const isContinueVisibleForTSS = isTSS && !isV3Plan
  const isSubCanceled = accountInfo.status !== 'active'
  const isPro12MoTerm = accountInfo?.planCode === 'pro-yp-m2m'
  const isStarterPlan = STARTER_PLANS.some(code =>
    accountInfo?.planCode?.includes(code),
  )
  const displaySyncAndMCPlans =
    planNeedsUpdate ||
    DISPLAY_MC_SYNC_PLANS.some(code => accountInfo?.planCode?.includes(code))
  const isAnnualPlan = accountInfo?.planCode.includes('annual')
  const annualPlan = isAnnualPlan && accountInfo?.planName.split('-')[0]

  const getCurrentPlanTerm = planCode => {
    switch (true) {
      case planCode?.includes('-m2m'):
        return 'monthly'
      case planCode?.includes('-annual'):
        return 'annual'
      case planCode?.includes('-semi'):
        return 'semi'
      default:
        return 'monthly'
    }
  }

  const getBillingPreferenceOptions = () => {
    const currTerm = getCurrentPlanTerm(accountInfo?.planCode)

    if (isV3Plan) {
      if (['BB', 'KY'].includes(selectedCountry)) {
        return currTerm === 'monthly' && !showThryvLeads
          ? RADIO_BILLING_PREFERENCES
          : RADIO_BILLING_PREFERENCES
      } else if (currTerm === 'monthly') return [RADIO_BILLING_PREFERENCES[0]]
      else return RADIO_BILLING_PREFERENCES
    }

    if (['BB', 'KY'].includes(selectedCountry))
      return [RADIO_BILLING_PREFERENCES[0]]

    return RADIO_BILLING_PREFERENCES
  }

  const fetchPlans = React.useCallback(async () => {
    let url = 'plans/prices/countries'
    const response = await client(url)
    setListOfPlansPrices(response)
  }, [client])

  const getInterval = () =>
    billingPreference?.value === BILLING_PREFERENCES[0]
      ? BILLING_PREFERENCES[0]
      : BILLING_PREFERENCES[1]

  const getPlanIcon = planName => {
    const planItem = PLANS_ICONS.filter(item =>
      planName.toLowerCase()?.includes(item.planName),
    )
    return planItem[0]?.icon
  }

  const getPlansPrices = React.useCallback(() => {
    if (listOfPlansPrices.length === 0) return
    if (!listOfPlansPrices[selectedCountry]) {
      toast.warning(
        `Thryv is not available in this country (${selectedCountry})`,
      )
      throw new Error()
    }
    const interval = getInterval()
    if (!listOfPlansPrices[selectedCountry][interval]) {
      toast.warning(`No plans available for this interval (${interval})`)
      setListOfPlans([])
      throw new Error()
    }
    const listPlans = listOfPlansPrices[selectedCountry][interval]
    const plans = listPlans.map(
      ({planName, amount, onboarding, name, planCode}) => ({
        name,
        planName,
        amount,
        planCode,
        onboarding,
        img: getPlanIcon(planName),
        industryType: PLAN_INDUSTRY_TYPES[name],
      }),
    )
    setListOfPlans(
      plans
        .filter(plan => {
          if (isLegacyPlan) return plan.name !== 'marketingCenter'
          if (isSyncPlan || displaySyncAndMCPlans || isSubCanceled) return plan
          return plan.name !== 'sync'
        })
        .filter(plan => {
          if (isV3Plan) return plan
          return plan.name !== 'thryvLeads'
        })
        .filter(plan => {
          if (
            (roles.includes('PremisePilot') ||
              roles.includes('LeadConverter') ||
              roles.includes('Admin')) &&
            isV3Plan
          ) {
            return plan
          }
          return plan.name !== 'marketingCenter'
        })
        .sort((a, b) => {
          let amount1 = a.amount
          let amount2 = b.amount
          if (typeof amount1 === 'string') {
            amount1 = a.amount.replace(',', '')
          }
          if (typeof amount2 === 'string') {
            amount2 = b.amount.replace(',', '')
          }
          return parseFloat(amount2) - parseFloat(amount1)
        }),
    )
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listOfPlansPrices,
    selectedCountry,
    accountInfo?.planCode,
    billingPreference,
  ])

  const onBillingPreferenceChange = value => {
    const newValue = value
    setBillingPreference(newValue)
    setSelectedPlan(null)
  }

  // const validateSstOnBehalf = async () => {
  //   try {
  //     let overrideRepInfo = await client(`orders/onbehalf/${overrideRepCode}`)
  //     if (Array.isArray(overrideRepInfo)) {
  //       overrideRepInfo = overrideRepInfo.filter(datum => {
  //         return datum.rep_first_name && datum.rep_last_name && datum.rep_email
  //       })[0]
  //     }
  //     setValue(
  //       'overrideRepInfo',
  //       `${overrideRepInfo.rep_first_name} ${overrideRepInfo.rep_last_name}`,
  //     )
  //     setValue('overrideRepFirst', overrideRepInfo.rep_first_name)
  //     setValue('overrideRepLast', overrideRepInfo.rep_last_name)
  //     setValue('overrideRepEmail', overrideRepInfo.rep_email)
  //   } catch (error) {
  //     if (error.status === 404) {
  //       setValue('overrideRepInfo', SST_SALES_REP_INFO_NOT_FOUND)
  //     }
  //   }
  //   trigger('overrideRepInfo')
  // }
  const handleOverrideRepOptionChange = selectedOption => {
    setSelectedOverridRep(selectedOption)
    const overrideRepInfo = selectedOption.salesRepInfo
    try {
      setValue('overrideRepInfo', `${overrideRepInfo.Name}`)
      setValue('overrideRepCode', overrideRepInfo.FederationIdentifier)
      setValue('overrideRepFirst', overrideRepInfo.FirstName)
      setValue('overrideRepLast', overrideRepInfo.LastName)
      setValue('overrideRepEmail', overrideRepInfo.Email)
    } catch (error) {
      if (error.status === 404) {
        setValue('overrideRepInfo', SST_SALES_REP_INFO_NOT_FOUND)
      }
    }
    trigger('overrideRepInfo')
  }

  const resetOnBehalf = async () => {
    setValue('salesRepInfo', '')
    setValue('salesRepCode', '')
    // setSelectedSalesRep(null)
    setSelectedSalesRep({label: '', name: ''})
  }

  const resetSstOnBehalf = async () => {
    setValue('overrideRepInfo', '')
    setValue('overrideRepCode', '')
    // setSelectedOverridRep('')
    setSelectedOverridRep({label: '', name: ''})
  }

  const handleContinue = updatedAddress => {
    if (accountInfo?.status !== 'active' && accountInfo?.pastDue) {
      setPastDueError(true)
      return
    }

    let data = {
      selectedPlan,
      billingPreference,
      salesRepCode,
      salesRepInfo,
      behalfFirst,
      behalfLast,
      behalfEmail,
      differentSalesRep,
      overrideRepCode,
      overrideRepInfo,
      overrideRepFirst,
      overrideRepLast,
      overrideRepEmail,
      overrideRep,
      xpCode,
    }
    if (!differentSalesRep.value || !salesRepCode) {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalf
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
      delete data.differentSalesRep
    }
    if (differentSalesRep.value) {
      delete data.xpCode
    }
    if (differentSalesRep.value === 'No') {
      delete data.salesRepInfo
      delete data.salesRepCode
      delete data.behalf
      delete data.behalfEmail
      delete data.behalfFirst
      delete data.behalfLast
      delete data.differentSalesRep
      delete data.xpCode
    }
    if (!overrideRep.value || !overrideRepCode) {
      delete data.overrideRepInfo
      delete data.overrideRepCode
      delete data.overrideRepId
      delete data.overrideRepEmail
      delete data.overrideRepFirst
      delete data.overrideRepLast
      delete data.overrideRep
    }
    updateBillingAddress(updatedAddress)
    nextStep(data)
  }

  React.useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  React.useEffect(() => {
    getPlansPrices()
  }, [getPlansPrices])

  React.useEffect(() => {
    const activePlan = formatPlanName(accountInfo.planName)
    if (Array.isArray(listOfPlans) && accountInfo.planName) {
      const currentPlan = listOfPlans.find(plan => {
        if (isAnnualPlan) {
          return plan.name === annualPlan.toLowerCase()
        }
        return plan.name === activePlan
      })
      setSelectedPlan(
        (isV3Plan &&
          !THRYV_PLANS.some(plan => accountInfo.planCode.includes(plan))) ||
          displaySyncAndMCPlans ||
          isStarterPlan ||
          isSubCanceled
          ? listOfPlans.find(plan => plan.name.includes('plus'))
          : isPro12MoTerm
          ? {
              amount: '299',
              industryType: 'thryv',
              name: 'professional',
              planCode: 'pro-yp-m2m',
              planName: 'Pro (12 mo. Term)',
            }
          : currentPlan,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo, setSelectedPlan, listOfPlans, billingPreference])

  React.useEffect(() => {
    if (
      (differentSalesRep.value && overrideRep.value) === true ||
      (differentSalesRep.value && overrideRep.value === false) ||
      (differentSalesRep.value === false && overrideRep.value)
    ) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }

    if (differentSalesRep.value === 'NO') {
      setIsSubmitButtonDisabled(false)
    }

    if (
      differentSalesRep.value &&
      salesRepInfo &&
      salesRepInfo !== SALES_REP_INFO_NOT_FOUND &&
      overrideRep.value === false
    ) {
      setIsSubmitButtonDisabled(false)
    }

    if (
      overrideRep.value &&
      overrideRepInfo &&
      overrideRepInfo !== SST_SALES_REP_INFO_NOT_FOUND &&
      differentSalesRep.value === false
    ) {
      setIsSubmitButtonDisabled(false)
    }

    if (
      differentSalesRep.value &&
      salesRepInfo &&
      salesRepInfo !== SALES_REP_INFO_NOT_FOUND &&
      overrideRep.value &&
      overrideRepInfo &&
      overrideRepInfo !== SST_SALES_REP_INFO_NOT_FOUND
    ) {
      setIsSubmitButtonDisabled(false)
    }
  }, [salesRepInfo, differentSalesRep, overrideRepInfo, overrideRep])

  return (
    <NewOrderLayout
      isLoading={isLoading}
      prevStep={prevStep}
      disableUpdateAddressModal={!selectedPlan || pastDueError}
      // disableOpenAddressModal={
      //   differentSalesRep?.value
      //     ? !salesRepInfo || salesRepInfo === SALES_REP_INFO_NOT_FOUND
      //     : false
      // }
      disableOpenAddressModal={isSubmitButtonDisabled}
      showCancelButton={true}
      cancelUrl="/all-accounts"
      nextStep={handleContinue}
      showUpdateAddressModal={!isTSS || isContinueVisibleForTSS}
      selectedCountry={selectedCountry}
      accountInfo={accountInfo}
      billingPreference={billingPreference}
      title="Upgrade Thryv Plan"
    >
      <div className="pl-5 mt-4">
        <AccountInfo data={accountInfo} />

        <br />
        <RadioGroup
          data={getBillingPreferenceOptions()}
          value={billingPreference}
          label="Billing Preferences"
          layoutStyles={{
            gap: '10px',
          }}
          disabled={isTSS}
          onChange={value => onBillingPreferenceChange(value)}
        />
      </div>
      {isTSS && isV3Plan ? (
        <div className="pl-5 mt-4" style={{justifySelf: 'end'}}>
          <Header fontWeight="medium" variant="h3">
            This plan cannot be edited or upgraded <br /> without contacting
            this client&apos;s BA.
          </Header>
          <Button
            variant="primary"
            className="d-flex align-items-center"
            style={{fontSize: '15px'}}
            onClick={() => navigate('/all-accounts')}
          >
            Back to All Accounts{' '}
            <Icon
              variant="arrowRight"
              type="solid"
              className="pl-2"
              height="13"
              color="#FFFFFF"
            />
          </Button>
        </div>
      ) : (
        <div>
          <ul className="pr-5 mt-4">
            {listOfPlans.length > 0 && (
              <>
                {listOfPlans.map(
                  (
                    {
                      planName,
                      value,
                      amount,
                      onboarding,
                      img,
                      name,
                      industryType,
                      planCode,
                    },
                    i,
                  ) => (
                    <PlanOption
                      testid={name}
                      key={i}
                      planName={planName}
                      planCode={planCode}
                      value={value}
                      amount={amount}
                      onboarding={onboarding}
                      interval={getInterval()}
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      img={img}
                      name={name}
                      listOfPlans={Array.isArray(listOfPlans) && listOfPlans}
                      currentPlanIndex={
                        Array.isArray(listOfPlans) &&
                        accountInfo.planName &&
                        findPlanIndex(
                          formatPlanName(
                            isAnnualPlan ? annualPlan : accountInfo.planName,
                          ),
                          listOfPlans,
                        )
                      }
                      addOns={accountInfo?.addOns}
                      hasMC={hasMC}
                      hasMCFree={hasMCFree}
                      isV3Plan={isV3Plan}
                      industryType={industryType}
                      roles={roles}
                      accountInfo={accountInfo}
                      isSubCanceled={isSubCanceled}
                      displaySyncAndMCPlans={displaySyncAndMCPlans}
                      isStarterPlan={isStarterPlan}
                    />
                  ),
                )}
              </>
            )}
          </ul>

          {pastDueError && (
            <div style={{paddingLeft: '33px'}}>
              <ParagraphText
                variant="reg"
                className="font-weight-bold"
                color="utility-error-danger"
              >
                Unable to proceed. Please contact Client Care.
              </ParagraphText>
            </div>
          )}

          {!isTSS && (
            <>
              <div className="d-flex flex-column" style={{paddingLeft: '33px'}}>
                <Controller
                  control={control}
                  name="differentSalesRep"
                  render={({field: {onChange, name}}) => (
                    <RadioGroup
                      data={DIFFERENT_SALES_REP_OPTIONS}
                      value={differentSalesRep}
                      label="Is this sale on behalf of another sales rep or partner?"
                      row
                      layoutStyles={{
                        gap: '10px',
                      }}
                      onChange={onChange}
                      name={name}
                    />
                  )}
                />
                {differentSalesRep?.value === true && (
                  <>
                    <div
                      className="input_container d-flex mt-4"
                      style={{width: '300px'}}
                    >
                      <AsyncSelect
                        cacheOptions
                        placeholder="Search by Name"
                        loadOptions={loadOptions}
                        onChange={handleSalesRepOptionChange}
                        value={selectedSalesRep}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: 'silver',
                            primary: 'black',
                          },
                        })}
                      />
                      {salesRepInfo && (
                        <Icon
                          style={{cursor: 'pointer', marginTop: '9px'}}
                          onClick={resetOnBehalf}
                          className="ml-2"
                          type="regular"
                          variant="circleX"
                          color="#FF5000"
                          height="18"
                          width="18"
                        />
                      )}
                    </div>
                  </>
                )}
                {!differentSalesRep?.value && (
                  <div className="mt-2">
                    <p>Please enter XP Code</p>
                    <Input
                      className="w-100"
                      type="text"
                      placeholder="XP Code"
                      aria-label="XP Code"
                      name="xpCode"
                      withLabel
                      labelType="floating"
                      variant="default"
                      register={register}
                      // errors={errors}
                    />
                  </div>
                )}
              </div>
              {frontendOverrideSst && (
                <div
                  className="d-flex flex-column"
                  style={{paddingLeft: '33px'}}
                >
                  <Controller
                    control={control}
                    name="overrideRep"
                    render={({field: {onChange, name}}) => (
                      <RadioGroup
                        data={OVERRIDE_SST_OPTIONS}
                        value={OVERRIDE_SST_OPTIONS[1]}
                        label="Do you want to override SST on this order?"
                        row
                        layoutStyles={{
                          gap: '10px',
                        }}
                        onChange={onChange}
                        name={name}
                      />
                    )}
                  />
                  {/* {overrideRep?.value && (
                    <>
                      <div
                        className="input_container d-flex mt-4"
                        style={{width: '300px'}}
                      >
                        <Input
                          className="w-100"
                          type="text"
                          placeholder="Search by ID"
                          aria-label="Search by ID"
                          name="overrideRepCode"
                          withLabel
                          labelType="static"
                          disabled={overrideRepInfo}
                          variant="default"
                          register={register}
                          errors={errors}
                        />
                        {overrideRepInfo && (
                          <Icon
                            style={{cursor: 'pointer', marginTop: '9px'}}
                            onClick={resetSstOnBehalf}
                            className="ml-2"
                            type="regular"
                            variant="circleX"
                            color="#FF5000"
                            height="18"
                            width="18"
                          />
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          data-testid="validate-email-button"
                          variant="primary"
                          className="new-order-button d-flex justify-content-around align-items-center mr-4 mt-4"
                          onClick={validateSstOnBehalf}
                        >
                          Validate
                        </Button>
                        <ParagraphText
                          variant="reg"
                          className="mb-0 text-thryv-stee mt-4"
                        >
                          {overrideRepInfo}
                        </ParagraphText>
                      </div>
                    </>
                  )} */}
                  {overrideRep?.value && (
                    <>
                      <div
                        className="input_container mt-4"
                        style={{width: '300px'}}
                      >
                        <AsyncSelect
                          cacheOptions
                          placeholder="Search by Name"
                          loadOptions={loadOptions}
                          onChange={handleOverrideRepOptionChange}
                          value={selectedOverrideRep}
                          theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: 'silver',
                              primary: 'black',
                            },
                          })}
                        />
                      </div>
                      {selectedOverrideRep &&
                      selectedOverrideRep.label &&
                      selectedOverrideRep.label.length ? (
                        <>
                          <Icon
                            className="reset-button"
                            type="regular"
                            variant="circleX"
                            color="#FF5000"
                            style={{
                              width: '18px',
                              height: '18px',
                              marginTop: '25px',
                              marginLeft: '10px',
                              marginRight: '10px',
                              alignSelf: 'center',
                            }}
                            onClick={resetSstOnBehalf}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </NewOrderLayout>
  )
}

export {UpgradePackageSelection}
